<template>
    <div>
        <v-bottom-navigation color="info" horizontal :background-color="colorfrist" dark>

          <v-btn to="/admin/timelines/transfer_teachers">
        <span>กำหนดการ </span>
        <v-icon>mdi-numeric-0-box</v-icon>
      </v-btn>



      <v-btn to="/admin/conditions_branch">
        <span>รายละเอียดเงือนไขสาขาวิชา </span>
        <v-icon>mdi-source-branch</v-icon>
      </v-btn>

      <v-btn to="/admin/conditions_transfer">
        <span>รายการเงือนไขสาขาวิชา</span>
        <v-icon>mdi-source-branch</v-icon>
      </v-btn>
      <v-btn to="/admin/man_power">
        <span>ข้อมูลอัตรากำลังรับย้าย</span>
        <v-icon>mdi-source-branch</v-icon>
      </v-btn>
      <v-btn to="/admin/man_powersummary">
          <span>ข้อมูลอัตรากำลังขาดเกิน/พิจารณาย้าย</span>
          <v-icon>mdi-source-branch</v-icon>
        </v-btn>
    </v-bottom-navigation>
    <v-bottom-navigation color="info" horizontal :background-color="colorsecord" dark>
      <v-btn to="/admin/transference_location_detail">
        <span>รายละเอียดผู้ยืนย้าย ประกอบพิจารณา </span>
        <v-icon>mdi-details</v-icon>
      </v-btn>
      <v-btn to="/admin/transference_location">
        <span>รายละเอียดผู้ยืนย้าย </span>
        <v-icon>mdi-details</v-icon>
      </v-btn>

      <v-btn to="/admin/transference_personnel">
        <span>ประมวลผล 1 </span>
        <v-icon>mdi-calculator</v-icon>
      </v-btn>

      <v-btn to="/admin/process_transfer">
        <span>ประมวลผล 2 [ระบบ]</span>
        <v-icon>mdi-calculator</v-icon>
      </v-btn>

      <v-btn to="/admin/process_transfer_switch_normal">
        <span>ประมวลผล 3 [สับเปลี่ยน ปกติ]</span>
        <v-icon>mdi-calculator</v-icon>
      </v-btn>

      <v-btn to="/admin/process_transfer_switch">
        <span>ประมวลผล 4 [สับเปลี่ยน]</span>
        <v-icon>mdi-calculator</v-icon>
      </v-btn>
      <v-btn to="/admin/conditons_transfer_success">
        <span>สรุปผล</span>
        <v-icon>mdi-bookmark-check</v-icon>
      </v-btn>      
      <v-btn to="/admin/Order_appoint">
        <span>รายงาน</span>
        <v-icon>mdi-printer</v-icon>
      </v-btn>
      <v-btn to="/admin/conditons_transfer_success_reportmv">
        <span>รายงานตัว</span>
        <v-icon>mdi-account-check</v-icon>
      </v-btn>
    </v-bottom-navigation>   
    </div>
</template>

<script>
export default {
    name: 'HRvecMovementmenu',

    data() {
        return {
            
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
    computed:{
        colorfrist() {
            return "#01579B";
        },
        colorsecord() {
            return "#004D40";
        },

    }
};
</script>

<style lang="scss" scoped>

</style>