<template>
  <div>
    <Movementmenu />
    <v-container fluid>
      <base-material-card
        icon="mdi-clipboard-text"
        title="ข้อมูลเงือนไขการรับย้ายสาขาวิชา"
        class="px-5 py-3"
      >
        <v-card class="mb-4 pa-2">
          <v-simple-table>
            <tbody>
              <tr>
                <td class="text-center">
                  <v-btn
                    rounded
                    color="info"
                    :href="
                      '#/admin/print_report_condition_all/' +
                        period_colleges.period_college_times +
                        '/' +
                        period_colleges.period_college_year
                    "
                    target="_blank"
                  >
                    <v-icon>mdi-printer</v-icon>
                    ข้อมูลเงือนไขการรับย้ายสาขาวิชา
                  </v-btn>
                </td>
                <td class="text-center">
                  <v-btn @click="AddConditionBranch()" rounded color="green">
                    <v-icon>mdi-bookmark-plus</v-icon> เพิ่มสาขากรณีพิเศษ</v-btn
                  >
                </td>
              </tr>
              <tr>
                <td class="text-center pa-2">
                  <v-autocomplete
                    v-model="search"
                    :items="collegesec"
                    item-text="college_name"
                    item-value="college_code"
                    label="ค้นหาวิทยาลัย"
                    rounded
                    outlined
                  ></v-autocomplete>
                </td>
                <td class="text-center">
                  <v-btn
                    v-if="search"
                    rounded
                    :href="
                      '#/college/print_condition/?id_ref=' +
                        period_colleges.period_college_times +
                        period_colleges.period_college_year +
                        search
                    "
                    target="_blank"
                    color="info"
                  >
                    <v-icon>mdi-printer</v-icon> พิมพ์เงื่อนไขวิทยาลัย</v-btn
                  >
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
          single-line
          hide-details
          dense
          filled
          class="mb-2"
        />
        <v-data-table
          color="success"
          :loading="loading"
          :headers="headers"
          :items="conditions_transfers"
          :search="search"
        >
          <template v-slot:item.select_item="{ item }">
            <v-checkbox
              v-model="search"
              :value="item.college_code"
            ></v-checkbox>
          </template>

          <template v-slot:item.status_confirm="{ item }">
            <div class="text-center">
              <v-chip color="warning" v-if="item.status_confirm === 'needless'">
                ไม่ประสงค์รับ
              </v-chip>
              <v-chip
                color="primary"
                v-else-if="item.status_confirm === 'receive'"
              >
                เสนอ
              </v-chip>
              <v-chip color="error" v-else>
                ยังไม่ได้บันทึก
              </v-chip>
            </div>
          </template>

          <template v-slot:item.Order_file="{ item }">
            <v-chip
              class="ma-1"
              :href="'/HRvecfiles/' + item.Order_file"
              target="_blank"
              v-if="item.Order_file"
              color="info"
              ><v-icon>mdi-link</v-icon></v-chip
            >

            <v-chip
              @click="
                deleteRecord(
                  item.id_ref,
                  item.id_branch,
                  item.con_id_branch_sub,
                  item.educational_level
                )
              "
              dark
              v-if="item.Order_file"
              color="red"
              ><v-icon>mdi-delete-forever</v-icon></v-chip
            >
          </template>

          <template v-slot:item.con_datail_branch="{ item }">
            <v-btn
              v-if="item.con_datail_branch === ''"
              color="info"
              @click="detail_select(item.id_cb)"
              fab
              x-small
            >
              <v-icon>
                mdi-comment-processing
              </v-icon>
            </v-btn>
            <v-chip
              color="green"
              dark
              v-else
              @click="detail_select(item.id_cb)"
            >
              <h2>{{ item.con_datail_branch }}</h2>
            </v-chip>
          </template>

          <v-alert
            slot="no-results"
            :value="true"
            color="error"
            icon="mdi-alert"
            >ไม่พบผลลัพธ์ "{{ search }}" ที่คุณกำลังค้นหา.</v-alert
          >
        </v-data-table>
      </base-material-card>

      <!--  -->

      <!--adconditions_branchdialog  -->
      <v-layout row justify-center>
        <v-dialog
          v-model="adconditions_branchdialog"
          persistent
          max-width="50%"
        >
          <v-card class="mx-auto pa-5">
            <base-material-card
              icon="mdi-account-multiple"
              title="เพิ่มข้อมูลประเภทสถานศึกษา"
              class="px-5 py-3 text_google"
            >
            </base-material-card>

            <v-card-text>
              <v-form ref="adconditions_branchform" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex md12>
                      <v-autocomplete
                        outlined
                        rounded
                        v-model="adconditions_branch.college_code"
                        :items="colleges"
                        item-text="college_name"
                        item-value="college_code"
                        label="สถานศึกษา"
                        required
                        :rules="[v => !!v || '']"
                      >
                      </v-autocomplete>
                    </v-flex>
                    <v-flex md12>
                      <v-autocomplete
                        outlined
                        rounded
                        v-model="adconditions_branch.id_branch"
                        :items="branch_s"
                        item-text="name_branch"
                        item-value="id_branch"
                        label="สาขาวิชาเอก"
                        required
                        :rules="[v => !!v || '']"
                        @change="branch_sub_dQueryAll()"
                      ></v-autocomplete>
                    </v-flex>
                    <v-flex md12>
                      <v-autocomplete
                        outlined
                        rounded
                        v-model="adconditions_branch.con_id_branch_sub"
                        :items="branch_sub_ds"
                        item-text="name_sub_branch"
                        item-value="id_branch_sub"
                        label="สาขางานที่ประสงค์รับ"
                        required
                        :rules="[v => !!v || '']"
                      ></v-autocomplete>
                    </v-flex>
                    <v-flex md12>
                      <v-text-field
                        outlined
                        rounded
                        type="number"
                        label="จำนวนครูที่สถานศึกษาต้องการที่จะรับย้าย"
                        v-model="adconditions_branch.quantity_n"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md12>
                      <v-select
                        outlined
                        rounded
                        :items="education_s"
                        item-text="title"
                        item-value="title"
                        label="ระดับการศึกษา"
                        v-model="adconditions_branch.educational_level"
                        required
                        :rules="[v => !!v || '']"
                      ></v-select>
                    </v-flex>

                    <v-flex md12>
                      <v-text-field
                        outlined
                        rounded
                        label="คุณลักษณะทางการศึกษา"
                        v-model="adconditions_branch.con_datail_branch"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12>
                      <v-file-input
                        v-model="Order_file"
                        accept=".pdf"
                        name="Order_file"
                        color="deep-purple accent-4"
                        counter
                        label="ไฟล์ .pdf"
                        placeholder="Select your files"
                        prepend-icon="mdi-paperclip"
                        outlined
                        rounded
                        :rules="rules"
                        :show-size="1000"
                      >
                        <template v-slot:selection="{ index, text }">
                          <v-chip
                            v-if="index < 2"
                            color="deep-purple accent-4"
                            dark
                            label
                            small
                          >
                            {{ text }}
                          </v-chip>

                          <span
                            v-else-if="index === 2"
                            class="text-overline grey--text text--darken-3 mx-2"
                          >
                            +{{ files.length - 2 }} File(s)
                          </span>
                        </template>
                      </v-file-input>
                    </v-flex>

                    <v-flex md12>
                      <v-text-field
                        outlined
                        rounded
                        label="Password ?"
                        v-model="adconditions_branch.secret"
                        required
                        :rules="[v => v == '0612698358']"
                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="show1 ? 'text' : 'password'"
                        @click:append="show1 = !show1"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="warning"
                large
                @click.stop="adconditions_branchdialog = false"
                rounded
                ><v-icon dark>mdi-close</v-icon> ยกเลิก</v-btn
              >
              <v-btn
                large
                color="success"
                @click.stop="adconditions_branchSubmit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model deleteconditions_branchdialog -->
      <v-layout>
        <v-dialog
          v-model="deleteconditions_branchdialog"
          persistent
          max-width="40%"
        >
          <v-card class="mx-auto pa-5">
            <base-material-card
              color="error"
              icon="mdi-delete"
              title="ลบข้อมูลประเภทสถานศึกษา"
              class="px-5 py-3 text_google"
            >
            </base-material-card>

            <v-card-text class="text_google">
              <v-card>
                <v-card-text>
                  <v-form ref="deleteconditions_branchform" lazy-validation>
                    <v-container grid-list-md>
                      <v-layout wrap>
                        <v-flex xs12>
                          ยืนยันการลบข้อมูล <br />
                          {{
                            editconditions_branch.college_name +
                              " : " +
                              editconditions_branch.id_branch +
                              " : " +
                              editconditions_branch.con_id_branch_sub +
                              " : " +
                              editconditions_branch.educational_level
                          }}
                        </v-flex>
                        <v-flex md12>
                          <v-text-field
                            outlined
                            rounded
                            label="Password ?"
                            v-model="adconditions_branch.secret"
                            required
                            :rules="[v => v == '0612698358']"
                            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="show1 ? 'text' : 'password'"
                            @click:append="show1 = !show1"
                          ></v-text-field>
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn large @click.stop="deleteconditions_branchdialog = false"
                ><v-icon dark>mdi-close</v-icon>ยกเลิก</v-btn
              >
              <v-btn
                large
                color="red darken-3"
                @click.stop="deleteconditions_branchSubmit()"
                dark
              >
                <v-icon dark>mdi-delete</v-icon>&nbsp;ลบ
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>
      <!--  -->

      <!--update_detailsdialog  -->
      <v-layout row justify-center>
        <v-dialog v-model="update_detailsdialog" persistent max-width="50%">
          <v-card class="mx-auto pa-5">
            <base-material-card
              icon="mdi-account-multiple"
              title="เพิ่มเติมรายละเอียดสาขาวิชา"
              class="px-5 py-3 text_google"
            >
            </base-material-card>

            <v-card-text>
              <v-form ref="addconditions_branchform" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex md12>
                      <v-text-field
                        label="รายละเอียดเพิ่มเติมสาขาวิชา"
                        v-model="conditions_branchs.con_datail_branch"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-container>
                <small>* จำเป็น</small>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="warning"
                large
                @click.stop="update_detailsdialog = false"
                rounded
                ><v-icon dark>mdi-close</v-icon> ยกเลิก</v-btn
              >
              <v-btn
                large
                color="success"
                @click.stop="adddetail_conditionsSubmit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>
    </v-container>

    <v-container fluid>
      <v-snackbar
        v-model="snackbar.show"
        top
        :timeout="snackbar.timeout"
        :color="snackbar.color"
      >
        <v-icon large>{{ snackbar.icon }}</v-icon>
        <v-card-text>
          {{ snackbar.text }}
        </v-card-text>

        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar.show = false"
            >Close</v-btn
          >
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>
<script>
import Movementmenu from "../../components/admin/movementmenu.vue";

export default {
  data() {
    return {
      loading: false,
      ApiKey: "HRvec2021",
      valid: true,
      show1: false,
      editconditions_transferdialog: false,
      adconditions_branchdialog: false,
      deleteconditions_branchdialog: false,
      editconditions_branchdialog: false,
      update_detailsdialog: false,
      update_details: {},
      conditions_branchs: [],

      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: ""
      },
      time_ss: [1, 2],
      year_ss: [2565, 2566, 2567, 2568, 2569, 2570],
      conditions_transfers: [],
      editconditions_transfer: {},
      search: "",
      pagination: {},
      headers: [
        { text: "เลือก", align: "left", value: "select_item" },
        { text: "สถานะ", align: "left", value: "status_confirm" },
        { text: "อ้างอิง", align: "center", value: "id_ref" },
        { text: "รหัสสถานศึกษา", align: "center", value: "college_code" },
        { text: "สถานศึกษา", align: "center", value: "college_name" },
        { text: "รหัส", align: "center", value: "id_branch" },
        { text: "สาขาวิชา", align: "left", value: "name_branch" },
        { text: "เพิ่มเติม", align: "center", value: "con_datail_branch" },
        { text: "วุฒิการศึกษา", align: "left", value: "educational_level" },
        { text: "จำนวนรับ", align: "left", value: "quantity_n" },
        { text: "ครั้งที่", align: "center", value: "time_s" },
        { text: "ปีที่", align: "center", value: "year_s" },
        { text: "วันที่ทำรายการ", align: "center", value: "date_time" },
        { text: "เพิ่มเติม", align: "center", value: "Order_file" },
        {
          text: "ผลการพิจารณา",
          align: "center",
          value: "actions",
          icon: "mdi-file-document-edit"
        }
      ],
      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],
      conditions_transferstatus: [],
      userstatus: {},
      period_colleges: [],
      period_college_enable_process: "1",
      adconditions_branch: {},
      editconditions_branch: [],
      colleges: [],
      education_s: [
        {
          title: "ปริญญาตรี"
        },
        {
          title: "ปริญญาโท",
          faculty: ""
        },
        {
          title: "ปริญญาเอก"
        }
      ],
      branch_sub_ds: [],
      branch_s: [],
      rules: [
        value => !value || value.size < 1048576 || "ไฟล์ไม่เกิน 1 MB / 1024 KB"
      ],
      Order_file: null,
      collegesec: [],
      search: ""
    };
  },
  async mounted() {
    /* await this.conditions_transferQueryAll(); */
    await this.period_collegeQuery();
    await this.conditions_transferQueryAll();
    await this.collegeQueryAll();
  },
  methods: {
    async collegeQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("college.php", {
          ApiKey: this.ApiKey
        })
        .finally(() => (this.loading = false));
      this.collegesec = result.data;
    },

    async college_codeCheck() {},
    async branch_sub_dQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("branch_sub_d.php", {
          ApiKey: this.ApiKey,
          id_main_branch: this.adconditions_branch.id_branch
        })
        .finally(() => (this.loading = false));
      this.branch_sub_ds = result.data;
    },
    async AddConditionBranch() {
      this.adconditions_branchdialog = true;
      this.adconditions_branch = {};
      let result = await this.$http.post("college.php", {
        ApiKey: this.ApiKey
      });
      this.colleges = result.data;
      let result_branch;
      result_branch = await this.$http.post("branch.php", {
        ApiKey: this.ApiKey
      });
      this.branch_s = result_branch.data;
    },
    async period_collegeQuery() {
      let result_period_college;
      result_period_college = await this.$http.post("period_college.php", {
        ApiKey: this.ApiKey,
        period_college_id: "401"
      });
      this.period_colleges = result_period_college.data;
    },

    async conditions_transferQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("conditions_transfer.php", {
          ApiKey: this.ApiKey,
          time_s: this.period_colleges.period_college_times,
          year_s: this.period_colleges.period_college_year
        })
        .finally(() => (this.loading = false));
      this.conditions_transfers = result.data;
    },
    async detail_select(id_cb) {
      this.loading = true;
      let result = await this.$http
        .post("conditions_transfer.php", {
          ApiKey: this.ApiKey,
          id_cb: id_cb
        })
        .finally(() => (this.loading = false));
      this.conditions_branchs = result.data;
      this.update_detailsdialog = true;
    },
    /* adddetail_conditionsSubmit */
    async adddetail_conditionsSubmit() {
      if (this.$refs.addconditions_branchform.validate()) {
        this.update_details.ApiKey = this.ApiKey;
        this.update_details.id_cb = this.conditions_branchs.id_cb;
        this.update_details.con_datail_branch = this.conditions_branchs.con_datail_branch;

        let result = await this.$http.post(
          "conditions_branch.update.php",
          this.update_details
        );
        if (result.data.status == true) {
          this.conditions_branch = result.data;
          Swal.fire({
            icon: "success",
            title: "ดำเนินการข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.conditions_transferQueryAll();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.update_detailsdialog = false;
      }
    },
    async adconditions_branchSubmit() {
      let result = "";
      let uploaded = null;
      if (this.$refs.adconditions_branchform.validate()) {
        this.adconditions_branch.ApiKey = this.ApiKey;
        this.adconditions_branch.condStatus = "save";
        this.adconditions_branch.id_ref =
          this.period_colleges.period_college_times +
          this.period_colleges.period_college_year +
          this.adconditions_branch.college_code;

        if (this.Order_file != "") {
          let formData = new FormData();
          let filename =
            this.adconditions_branch.college_code +
            "" +
            this.time_stamp +
            "" +
            "CBEdit.pdf";
          formData.append("ApiKey", this.ApiKey);
          formData.append("file", this.Order_file);
          formData.append("filename", "../HRvecfiles/" + filename);
          result = await this.$http.post("uploadfile.php", formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          });
          if (result.data.status == true) {
            this.adconditions_branch.Order_file = filename;
          } else {
          }
          uploaded = true;
        } else {
          uploaded = false;
        }
        result = await this.$http.post(
          "conditions_branch.insert.php",
          this.adconditions_branch
        );
        if (result.data.status && uploaded) {
          Swal.fire({
            icon: "success",
            title: "ดำเนินการข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
        } else {
          if (!uploaded && upload) {
            Swal.fire({
              icon: "warning",
              title: "ดำเนินการผิดพลาดแบบไฟล์ไม่ถูกต้อง",
              showConfirmButton: false,
              timer: 1500
            });
          } else {
            Swal.fire({
              icon: "warning",
              title: "ดำเนินการผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
        }
        await this.conditions_transferQueryAll();
        this.adconditions_branchdialog = false;
      }
    },
    async deleteRecord(
      id_ref,
      id_branch,
      con_id_branch_sub,
      educational_level
    ) {
      let result = await this.$http.post("conditions_branch.php", {
        ApiKey: this.ApiKey,
        id_ref: id_ref,
        id_branch: id_branch,
        con_id_branch_sub: con_id_branch_sub,
        educational_level: educational_level
      });
      this.editconditions_branch = result.data;
      this.deleteconditions_branchdialog = true;
    },
    async deleteconditions_branchSubmit() {
      if (this.$refs.deleteconditions_branchform.validate()) {
        this.editconditions_branch.ApiKey = this.ApiKey;
        let result_delete = await this.$http.post("deletefile.php", {
          ApiKey: this.ApiKey,
          filename: "../HRvecfiles/" + this.editconditions_branch.Order_file
        });
        let result = await this.$http.post(
          "conditions_branch.delete.php",
          this.editconditions_branch
        );
        if (result.data.status == true) {
          Swal.fire({
            icon: "success",
            title: "ดำเนินการข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.conditions_transferQueryAll();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.deleteconditions_branchdialog = false;
      }
    }
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;
      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    color() {
      return "cyan darken-4";
    },
    time_stamp() {
      let time = Date.now();
      return time;
    },
    date_today_log() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear() + 543;
      let time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      today = dd + "/" + mm + "/" + yyyy + "/" + time;
      return today;
    }
  },
  components: { Movementmenu }
};
</script>
